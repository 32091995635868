<template>
  <div class="container font-open-sans">
    <br />
    <div class="purple-font"><strong class="fs-20 fw-700 mb-10">Users List</strong></div>
    <br />
    <div class="row">
      <div class="col l4 m3 s12 pl-0 input-box">
        <select
          name=""
          id="select-input"
          class="browser-default pointer"
          @change="selectRole($event)"
          v-model="selectedRoleId"
        >
          <option value="0" :selected="selectedRoleId === 0">Select Role</option>
          <option v-for="role in rolelist" :key="role.RoleId" :value="role.RoleId">
            {{ role.RoleName }}
          </option>
        </select>
      </div>
      <div class="col l3 m3 s12 pl-20 input-box">
        <select
          name=""
          id="select-input"
          class="browser-default pointer"
          @change="selectIsActive($event)"
          v-model="selectedActive"
        >
          <option value="-1" :selected="selectedActive === -1">All Status</option>
          <option v-for="obj in activeList" :key="obj.value" :value="obj.value">
            {{ obj.name }}
          </option>
        </select>
      </div>
      <div class="col l3 m3 s12 pl-20 input-box">
        <input
          type="text"
          class="browser-default"
          v-model="searchName"
          placeholder="Search by name"
        />
      </div>
      <div class="col l1 m1 s12 pl-20 input-box">
        <button
          class="test-btn w-100"
          style="
            background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
            padding: 13px 10px;
          "
          @click="searchByName()"
        >
          Search
        </button>
      </div>
      <div class="col l1 m1 s12 pl-20 input-box">
        <button
          class="test-btn w-100"
          style="
            background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
            padding: 13px 10px;
          "
          @click="clearFilters()"
        >
          Clear
        </button>
      </div>
    </div>
    <div class="scroll-verticle-bar scroll-bar" v-if="listLength > 0">
      <div class="practice-details flex row s12 m12" v-for="data in usersList" :key="data.UserId">
        <div class="col m8 m-0">
          <div class="title-row flex flex-start">
            <div :title="'IsActive: ' + data.IsActive">
              <img
                :src="require(`../../assets/green-tick.svg`)"
                class="icon-bullet"
                v-if="data.IsActive === true"
              />
              <img
                :src="require(`../../assets/wrong13.svg`)"
                class="icon-bullet"
                v-if="data.IsActive === false"
              />
            </div>
            <div class="fs-16 fw-600 truncate pl-5" :title="'Name: ' + data.Name">
              {{ data.Name }}
            </div>
          </div>
          <div class="row">
            <div class="padding-lr-20 m-0">
              <div class="col m6 truncate flex items-center" :title="'EmailId: ' + data.EmailId">
                <img :src="require(`../../assets/Mail.svg`)" class="icon-bullet" />
                <span class="fw-500 fs-14 pl-5">{{
                  data.EmailId !== undefined && data.EmailId != null ? data.EmailId : null
                }}</span>
              </div>
              <div class="col m3 truncate flex items-center" :title="'Mobile: ' + data.Mobile">
                <img :src="require(`../../assets/Call.svg`)" class="icon-bullet" />
                <span class="fw-500 fs-14 pl-5">{{
                  data.Mobile !== undefined && data.Mobile !== null
                    ? formatString(data.Mobile)
                    : null
                }}</span>
              </div>
              <div class="col m3 truncate flex items-center" :title="'DOB : ' + data.DateOfBirth">
                <img :src="require(`../../assets/cake.svg`)" class="icon-bullet" />
                <span class="fw-500 fs-14 pl-5">{{
                  data.DateOfBirth !== undefined && data.DateOfBirth !== null
                    ? formatDate(data.DateOfBirth)
                    : null
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col m4 p-0 flex flex-around items-center">
          <p
            v-if="
              data.RoleName === 'Student' ||
              data.RoleName === 'Faculty' ||
              data.RoleName === 'Demo Student'
            "
            class="tooltip pointer"
            style="height: 36px; margin: 0px !important"
            @click="(showResetModal = true), resetDetails(data.EmailId)"
          >
            <img :src="require(`../../assets/reset-password.svg`)" class="icon-button" />
            <span class="tooltiptext">Reset Password</span>
          </p>
          <p
            v-if="
              data.RoleName === 'Student' ||
              data.RoleName === 'Faculty' ||
              data.RoleName === 'Demo Student'
            "
            class="tooltip pointer"
            style="height: 36px; margin: 0px !important"
            @click="editUser(data.UserId)"
          >
            <img :src="require(`../../assets/EditImg.svg`)" class="icon-button" />
            <span class="tooltiptext">Edit User</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="text-center error-box">
      <img
        :src="require(`../../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">No users available.</p>
    </div>
    <Modal3 :show="showPasswordModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div>
          <div class="flex flex-between items-center password-head">
            <span class="fs-16 fw-700">Regenerated password</span>
            <div @click="showPasswordModal = false" class="modal-close-button pointer">
              <span class="modal-close-icon">close</span>
            </div>
          </div>
          <div class="password-box">
            <div class="mb-10">Password regenerated successfully. Your new password is:</div>
            <div id="password-container" style="display: inline-flex">
              <span id="password">{{ password }}</span>
              <img id="copy-icon" :src="require(`../../assets/copy.svg`)" @click="copyPassword()" />
            </div>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal3 :show="showResetModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div>
          <div class="flex flex-between items-center password-head">
            <span class="fs-16 fw-700">Reset password</span>
            <div @click="showResetModal = false" class="modal-close-button pointer">
              <span class="modal-close-icon">close</span>
            </div>
          </div>
          <div class="p-20">
            <div class="heading-question">Are you sure you want to change password?</div>
            <div class="mt-10">
              <label key="auto-generate-password" class="fs-12 fw-600" style="margin-right: 12px">
                <input name="subject" type="radio" value="1" v-model="selectedOption" />
                <span>Auto-generate password</span>
              </label>
            </div>
            <div>
              <label key="enter-password" class="fs-12 fw-600" style="margin-right: 12px">
                <input name="subject" type="radio" value="2" v-model="selectedOption" />
                <span>Enter new password (alphanumeric and at least 8 characters long)</span>
              </label>
            </div>
            <div class="mt-10">
              <input
                v-if="selectedOption === '2'"
                class="password-input"
                type="text"
                placeholder="Enter password"
                v-model="password"
              />
              <p v-if="showLengthError" class="error-msg">
                Password should be min 8 & max 30 characters.
              </p>
              <p v-if="showSymbolError" class="error-msg">
                Password cannot contain special characters
              </p>
            </div>
            <div class="mt-20">
              <button @click="resetPassword()" class="reset-password-button">Continue</button>
              <button class="cancel-password-button" @click="showResetModal = false">Cancel</button>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import moment from "moment";
import MobileAPI from "../../Mobileapi";
import Doubtapi from "../../Doubtapi";
import Modal3 from "../../components/Modal3.vue";

const toast = useToast();

export default {
  data() {
    return {
      usersList: [],
      typeTest: 1,
      courseId: null,
      getUser: null,
      startEnable: false,
      userId: null,
      affiliationUserName: null,
      currentPage: 1,
      listLength: 0,
      totalQnsList: 0,
      startPageNumber: 1,
      endPageNumber: 20,
      totalCount: 0,
      next: true,
      filterTriggered: false,
      subjectList: [],
      subjectFilters: "",
      orginalApiData: "",
      selectedRoleId: 0,
      subjectsList: "",
      selectedActive: -1,
      selectedChapterId: 0,
      topicsList: "",
      chaptersList: "",
      selectedQuestionTypeId: 0,
      sourceTypeId: 0,
      rolelist: [],
      activeList: [
        { name: "Active", value: 1 },
        { name: "InActive", value: 0 },
      ],
      datalist: [],
      selectedroleid: null,
      RoleId: "",
      searchValue: "",
      User: [],
      showResetModal: false,
      password: "",
      selectedOption: 1,
      showPasswordModal: false,
      email: "",
      showLengthError: false,
      showSymbolError: false,
      searchName: "",
    };
  },
  components: {
    Modal3,
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  created() {
    const user = this.$store.getters.user;
    this.$store.dispatch("showLoader", true);

    MobileAPI.getAffiliationUserRoleList(user.AffiliationId, (response) => {
      this.rolelist = response.data;
    });
    this.$store.dispatch("showLoader", false);
  },
  methods: {
    searchByName() {
      this.getUserList();
    },
    getUserList() {
      const user = this.$store.getters.user;
      const data = {
        affiliationId: user.AffiliationId,
        roleId: this.selectedRoleId,
        pageNo: this.currentPage,
        isActive: this.selectedActive === -1 ? null : Number(this.selectedActive),
        name: this.searchName !== "" && this.searchName !== undefined ? this.searchName : null,
      };
      MobileAPI.getAffiliationUserRoleDetail(data, (resp) => {
        if (resp.responseCode === 200) {
          if (resp.data.length > 0) {
            this.$store.dispatch("showLoader", false);
            this.listLength = 0;
            this.usersList = resp.data;
            this.orginalApiData = resp.data;
            this.listLength = this.usersList.length;
            if (this.next) {
              this.totalCount += resp.data.length;
            }
            this.totalQnsList = this.usersList[0].TotalUsers;
            if (this.totalCount === this.totalQnsList) {
              this.endPageNumber = this.totalCount;
            }
            this.listLength = this.usersList.length;
          } else {
            toast.error("No users found for given role.", {
              timeout: 2500,
            });
            this.usersList = [];
            this.listLength = 0;

            this.$store.dispatch("showLoader", false);
          }
        } else {
          toast.error(`${resp.data.info}`, {
            timeout: 2500,
          });
          this.$store.dispatch("showLoader", false);
        }
      });
    },
    selectRole(event) {
      this.selectedRoleId = Number(event.target.value);
      this.getUserList();
    },
    selectIsActive(event) {
      this.selectedActive = Number(event.target.value);
      this.getUserList();
    },
    formatString(string) {
      const str = string.split(",");
      if (str.length === 1) {
        if (str[0].length > 20) {
          str[0] = str[0].slice(0, 20);
          return `${str[0]}..`;
        }
        return str[0];
      }
      if (str[0].length > 20) {
        str[0] = str[0].slice(0, 20);
      }
      return `${str[0]}... +${str.length - 1}`;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("D MMM YYYY");
      }
      return null;
    },
    clearFilters() {
      this.selectedRoleId = 0;
      this.selectedActive = -1;
      this.searchName = "";
      this.getUserList();
    },
    reset() {
      const user = this.$store.getters.user;
      this.$store.dispatch("showLoader", true);

      MobileAPI.getAffiliationUserRoleList(user.AffiliationId, (response) => {
        this.rolelist = response.data;
      });
      this.getUserList();
      this.$store.dispatch("showLoader", false);
    },
    editUser(UserId) {
      this.$router.push({
        name: "EditUser",
        params: { EditId: UserId, pageName: "User" },
      });
    },
    clearEditUserData() {
      localStorage.removeItem("EditPage");
      localStorage.removeItem("EditId");
    },
    copyPassword() {
      const password = document.getElementById("password").innerText;
      navigator.clipboard.writeText(password);
      toast.info("Password copied to clipboard.", {
        timeout: 2500,
      });
    },
    isAlphanumeric(str) {
      // to check if password contains special symbol
      const alphanumericRegex = /^[a-zA-Z0-9]+$/;
      return alphanumericRegex.test(str);
    },
    resetPassword() {
      this.resetErrorMsgs();
      if (this.selectedOption === "2" && (this.password.length < 8 || this.password.length > 30)) {
        this.showLengthError = true;
      } else if (this.selectedOption === "2" && !this.isAlphanumeric(this.password)) {
        this.showSymbolError = true;
      } else if (Number(this.selectedOption) === 1) {
        this.password = "";
        // to generate alphanumeric 8 character password
        const passwordLength = 8;
        for (let i = 0; i < passwordLength; i += 1) {
          const randomCharCode = Math.floor(Math.random() * 62);
          let randomChar;
          if (randomCharCode < 10) {
            randomChar = String.fromCharCode(randomCharCode + 48); // generates a number
          } else if (randomCharCode < 36) {
            randomChar = String.fromCharCode(randomCharCode + 55); // generates uppercase character
          } else {
            randomChar = String.fromCharCode(randomCharCode + 61); // generates lowercase character
          }
          this.password += randomChar;
        }
      }
      if (!this.showLengthError && !this.showSymbolError) {
        this.$store.dispatch("showLoader", true);
        const data = { userName: this.email, password: this.password };
        Doubtapi.updateUserPassword(data, (response) => {
          if (response.statusCode === 200) {
            this.$store.dispatch("showLoader", false);
            this.copied = false;
            this.showResetModal = false;
            this.showPasswordModal = true;
          } else {
            this.$store.dispatch("showLoader", false);
            toast.info("Some error occured.", {
              timeout: 2500,
            });
          }
        });
      }
    },
    resetDetails(email) {
      this.selectedOption = 1;
      this.resetErrorMsgs();
      this.email = email;
      this.password = "";
    },
    resetErrorMsgs() {
      this.showLengthError = false;
      this.showSymbolError = false;
    },
  },
};
</script>

<style scoped>
.dashboard {
  height: max-content;
}

.input-box {
  margin-bottom: 10px;
}

.heading {
  margin-top: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.roles {
  display: flex;
  flex-wrap: wrap;
}

.role-card {
  margin: 10px 20px;
  height: 11.25rem;
  width: 17.5rem !important;
  display: flex;
  justify-content: space-around;
  border-radius: 0.313rem;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}

.roles :hover {
  background: #6a2f85;
  color: #ffffff;
}

.role-card-content {
  display: flex;
  flex-direction: column;
  font-size: large;
  margin: 25px 0;
}

.role-name {
  font-size: 25px;
}

.active-count {
  font-size: 30px;
}

.total-count {
  font-size: 15px !important;
}

.table-body-row {
  border-radius: 5px;
  background: white;
}

table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtbl thead tr td {
  border-radius: 5px;
}

table.qtbl thead tr td:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
}

#password-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#password {
  text-align: center;
  padding: 5px 20px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Open Sans, sans-serif;
  white-space: break-spaces;
  margin-right: 10px;
  justify-content: center;
}

.error-msg {
  color: red;
  font-size: 12px;
  white-space: break-spaces;
}

.password-box {
  padding: 20px;
  white-space: break-spaces;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Open Sans, sans-serif;
}

#copy-icon {
  height: 26px;
  cursor: pointer;
}

.password-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.password-input {
  width: 240px !important;
  height: 32px !important;
  margin-bottom: 10px !important;
}

.modal-close-button {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.modal-close-icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

.reset-password-button {
  width: 125px;
  height: 36px;
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.cancel-password-button {
  width: 125px;
  height: 36px;
  border: 1px solid #ff7675;
  border-radius: 5px;
  color: #ff7675;
  text-transform: uppercase;
  background: white;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.heading-question {
  color: #333333;
  font-family: Open Sans, sans-serif;
  font-weight: 700 !important;
  white-space: pre-line;
}

[type="radio"] + span:before,
[type="radio"] + span:after {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  margin: 4px;
  width: 13px;
  height: 13px;
  z-index: 0;
  transition: 0.28s ease;
  border: none !important;
  background: rgba(51, 51, 51, 0.2);
}

[type="radio"]:not(:checked) + span,
[type="radio"]:checked + span {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #333333;
  opacity: 0.7;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #ff9421 !important;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #ff9421 !important;
}

.ml-0 {
  margin-left: 0% !important;
}

.scroll-bar {
  max-height: calc(100vh - 265px);
}

.error-box {
  margin-bottom: 20px;
}

.pagination {
  padding: 13.15px;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tooltip {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  background-color: #9bdcff;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #9bdcff;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #9bdcff;
  backdrop-filter: #9bdcff;
  transform: rotate(45deg);
  background: #9bdcff;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}

.test-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 9px 13px;
  cursor: pointer;
}

input[type="text"] {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
}

.pagination {
  margin-bottom: 10px;
}

.prev-btn {
  margin-right: 20px;
}

.show-index {
  color: #333333;
}

.br-4 {
  border-radius: 4px;
}

.padding-lr-20 {
  padding: 0px 20px !important;
}

.border-right {
  height: max-content;
  border-right: 1px solid #d0d0d0;
}

.pl-25 {
  padding-left: 25px !important;
}

.p-20 {
  padding: 20px !important;
}

.action-icons {
  justify-content: flex-start;
  margin-top: 7px;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.btn-last {
  text-align: end;
  margin-top: 5px;
}

.title-row {
  margin-right: 20px;
  padding: 10px 20px;
}

.font-open-sans {
  font-family: "Open Sans";
  font-style: normal;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.m-0 {
  margin: 0 !important;
}

.purple-font {
  color: #642c90;
}
.c-blue {
  color: #3751ff;
}

.c-green {
  color: #16a085;
}

.c-red {
  color: #ef727a;
}

.mb-10 {
  margin-bottom: 8px;
}

.radio-btn {
  color: black;
  font-weight: 400;
  margin-right: 10px;
  margin-top: 8px;
}

.icon-bullet {
  width: 24px;
}

.icon-button {
  width: 36px;
}

.flex-justify-space-around {
  display: flex;
  justify-content: space-around;
}

.flex-direction-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-10 {
  padding-right: 10px;
}

.text-500 {
  font-weight: 500px;
  font-size: 14px;
}

.test {
  background-color: #338510;
}

.practice-details {
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
}

.submit-btn {
  gap: 20px;
  display: flex;
}

.browser-default {
  background: #f3f5fc;
  border-radius: 4px !important;
  border-color: #f3f5fc !important;
  height: 34px;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #3751ff;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #3751ff;
}

.lblTest {
  margin-left: 10px;
}

.testHLinkButton {
  width: 139px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #ff9421;
  height: 35px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600px;
  cursor: pointer;
}

.testHLinkButton:hover {
  cursor: pointer;
}

.getbutton {
  background: linear-gradient(90.2deg, #3751ff 0.16%, #3751ff 98.41%);
  border-radius: 5px;
  width: 150px;
}

.scroll-verticle,
.scroll-verticle-bar {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
  width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.navigation-btn.disable {
  pointer-events: none;
}

.navigation-btn {
  width: 30px;
  height: 30px;
  line-height: 38px;
  text-align: center;
  background-color: white;
  color: #8c8585;
  border: 1px solid #333333 !important;
  border-radius: 3px;
}

.navigation-btn.currentPage {
  border-color: #3751ff !important;
  color: #3751ff;
}

.input-box {
  padding-right: 0 !important;
}

select {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}

#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 43px;
  padding-left: 7px;
}

@media screen and (max-width: 1120px) {
  .display-block {
    display: block !important;
  }

  .margin-tb-10-1120 {
    margin: 10px 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .flex-align-start {
    align-items: flex-start;
  }

  .left-align-wht {
    width: 26px;
    height: 26px;
    text-align: center;
  }

  .padding-0-600 {
    padding: 0px !important;
  }

  .padding-l-20 {
    padding-left: 20px !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0px !important;
  }

  .margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .text-align-left {
    text-align: left !important;
  }
}
</style>
